import { ElementRef, ExistingProvider, InjectOptions, ProviderToken, inject } from '@angular/core';

export function injectNode<T extends Node>(nodeType: ProviderToken<T>): T;
export function injectNode<T extends Node>(
  nodeType: ProviderToken<T>,
  options: InjectOptions & { optional?: false },
): T;
export function injectNode<T extends Node>(nodeType: ProviderToken<T>, options: InjectOptions): T | null;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function injectNode(nodeType: any, options?: any) {
  return inject(ElementRef, options)?.nativeElement ?? null;
}

/**
 * Use in a component or directive's `providers` array to allow it to fill in for its superclass.
 *
 * ```
 * export abstract class Foo {}
 *
 * @Directive({selector: '[appBar]'})
 * export class Bar extends Foo {}
 *
 * @Component(...)
 * export class Baz {
 *   fooParent = inject(Foo); // Matches a parent with the `appBar` directive
 *   fooChild = contentChild(Foo); // Matches a child with the `appBar` directive
 * }
 * ```
 */
export function provideSelfAsSuperclass<Superclass, Subclass extends Superclass>(sup: Superclass, sub: Subclass) {
  const provider: ExistingProvider = { provide: sup, useExisting: sub };
  return provider;
}
