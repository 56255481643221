const defineTheme = <ID extends string, T extends string>(
  id: ID,
  type: T,
): { id: ID; cls: string | null; type: T } => ({
  id,
  cls: id !== 'main' ? `theme-${id}` : null,
  type,
});

const _themes = [
  defineTheme('main', 'main'),
  defineTheme('gray', 'main'),

  // Product
  defineTheme('green-tea', 'product'),
  defineTheme('biodiversity-blue', 'product'),
  defineTheme('orchid', 'product'),
  defineTheme('biodiversity-blue', 'product'),

  // Content
  defineTheme('pink', 'content'),
  defineTheme('gray', 'content'),
  defineTheme('guidance-blue', 'content'),
  defineTheme('pink', 'content'),
  defineTheme('meadow', 'content'),

  // Support
  // defineTheme('warning-orange', 'support'),

  // Data
  // defineTheme('indicator-spectrum', 'data'),

  // Recommendation (from SAQ mitigation scores) palettes
  defineTheme('recommendation-cherry', 'recommendation'),
  defineTheme('recommendation-blue', 'recommendation'),
  defineTheme('recommendation-aqua', 'recommendation'),
] as const;

export type ThemeId = (typeof _themes)[number]['id'];
export type ThemeType = (typeof _themes)[number]['type'];
export type Theme = {
  readonly id: ThemeId;
  readonly type: ThemeType;
  readonly cls: string | null;
};

export const themes: ReadonlyArray<Theme> = _themes;
export const themeMap = _themes.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}) as Record<ThemeId, Theme>;
