// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { animate, query, style, transition, trigger } from '@angular/animations';
import { Component, InputSignal, booleanAttribute, computed, input } from '@angular/core';
import { Theme, themes } from '../theming/themes';

@Component({
  selector: 'fsn-spinner',
  standalone: true,
  template: `<!---->
    <span class="cdk-visually-hidden">Loading</span>
    <div
      class="masked-image"
      [style.mask-image]="'url(/assets/images/loading/' + spinner() + '.gif)'"
      [style.width.px]="sizeDimensions()"
      [style.height.px]="sizeDimensions()"
    ></div>

    <!----> `,
  styles: `
    :host {
      display: block;
      transition: all var(--fsn-anim-tf-chill-soft);
    }

    .masked-image {
      mask-size: cover;
      mask-repeat: no-repeat;
      mask-mode: luminance;
      background-color: var(--fsn-comp-spinner-selected-color);
    }
  `,
  host: {
    '[role]': '"progressbar"',
    '[attr.aria-live]': '"polite"',
    '[@showHide]': 'true',
    '[@.disabled]': 'noTransition()',
    '[class]': 'themeClass()',
  },
  animations: [
    trigger('showHide', [
      transition(':enter', [
        query('.masked-image', style({ opacity: 0.01 })),
        query('.masked-image', animate('2s 0.5s', style({ opacity: 1 }))),
      ]),
      // prettier-ignore
      transition(':leave', [
        query('.masked-image', style({ opacity: 1 })),
        query('.masked-image', animate('0.5s', style({ opacity: 0 }))),
      ]),
    ]),
  ],
})
export class SpinnerComponent {
  readonly noTransition = input(false, { transform: booleanAttribute });
  readonly spinner: InputSignal<'crosshair' | 'fairsupply'> = input('fairsupply');
  readonly theme = input<Theme['id'] | undefined>(undefined);
  readonly themeClass = computed(() => {
    const currentTheme = themes.find(t => t.id === this.theme()) ?? themes[0];
    return currentTheme.cls || '';
  });

  readonly sizeDimensions = computed(() => (this.size() === 'small' ? 40 : 64));
  readonly size: InputSignal<'small' | 'large'> = input('large'); // Default to 'large'
}
